<section>
  <div class="filters">
    <form class="lft" [formGroup]="searchFilters" (ngSubmit)="searchSubmit()">
      <mat-form-field class="mt-select" appearance="outline">
        <mat-label>Supplier Id</mat-label>
        <input matInput formControlName="supplierId" />
      </mat-form-field>
      <mat-form-field class="mt-select" appearance="outline">
        <mat-label>Vertical</mat-label>
        <mat-select formControlName="vertical" disableOptionCentering>
          <mat-option *ngFor="let vertical of verticals$ | async" [value]="vertical.name">
            {{ vertical.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mt-select" appearance="outline">
        <mat-label>Region</mat-label>
        <mat-select formControlName="region" disableOptionCentering>
          <mat-option *ngFor="let region of regions$ | async" [value]="region.name">
            {{ region.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <button mat-flat-button type="submit" color="primary" class="mx-2 srch-btn">
        <mat-icon>search</mat-icon> Search
      </button>
      <button (click)="onReset()" mat-flat-button class="resetButton srch-btn" type="button">
        <mat-icon class="icon">refresh</mat-icon>Reset
      </button>
    </form>
    <div class="rht">
        <button mat-button color="primary" (click)="download()">Download</button>
    </div>
  </div>
  <div class="rht">
    <app-data-summary-pagination [defaultPageSize]="100" [totalElements]="totalRecords" (pageChange)="handlePageChange($event)"> </app-data-summary-pagination>
</div>
    <div class="inner-cpsticky example-container mat-elevation-z8 table-set">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="supplierId">
                <th mat-header-cell *matHeaderCellDef>
                    Supplier Id
                </th>
                <td mat-cell *matCellDef="let element let i=index">
                    {{element.supplier_id}}
                </td>
            </ng-container>
            <ng-container matColumnDef="supplierName">
                <th mat-header-cell *matHeaderCellDef>
                    Supplier Name
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.supplier_name}}
                </td>
            </ng-container>
            
            <ng-container matColumnDef="avgGM">
                <th mat-header-cell *matHeaderCellDef>Avg <br> GM%</th>
                <td mat-cell *matCellDef="let element"> {{ element.gm_percent }} </td>
            </ng-container>

            <ng-container matColumnDef="avgGM2">
                <th mat-header-cell *matHeaderCellDef>Avg GM% <br> Other Supplier</th>
                <td mat-cell *matCellDef="let element"> {{ element.other_gm_percent }} </td>
            </ng-container>  
            
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef>Category</th>
                <td mat-cell *matCellDef="let element"> {{ element.category }} </td>
            </ng-container>

            <ng-container matColumnDef="risk">
                <th mat-header-cell *matHeaderCellDef>Risk Assessment</th>
                <td mat-cell *matCellDef="let element"> 
                    <div [ngSwitch]="element.riskFactor">
                        <div *ngSwitchCase="-1">
                          <mat-icon class="icon-down">trending_down</mat-icon>
                        </div>
                        <div *ngSwitchCase="0">
                          <mat-icon class="icon-flat">trending_flat</mat-icon>
                        </div>
                        <div *ngSwitchCase="1">
                          <mat-icon class="icon-up">trending_up</mat-icon>
                        </div>
                    </div>
                 </td>
            </ng-container>
            <tr mat-header-row class="nestedHeader row" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row (click)="openInfoPage(row)" class="content-row"  *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</section>
