<div class="d-flex justify-content-end px-2">
    <div *ngIf="totalElements != 0">
        <span class="mx-2 text-muted">Items per page: </span>
        <mat-form-field class="limit-field">
            <mat-select [formControl]="pageSize">
                <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{size}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="pagination-controls">
        <mat-form-field class="page-field" *ngIf="totalElements">
            <mat-select [formControl]="currentPage">
                <mat-option [value]="i + 1" *ngFor="let page of [].constructor(totalPages); let i = index">Page {{i + 1}} of {{totalPages}}</mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <span class="mx-3" *ngIf="!totalElements" style="    text-align: center; margin-top: 13px;">Page {{currentPage.value}}</span> -->
        <button mat-icon-button [disabled]="currentPage.value === 1" (click)="previous()">
            <mat-icon>arrow_backward</mat-icon>
        </button>
        <button mat-icon-button [disabled]="currentPage.value === totalPages" (click)="next()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>