import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, finalize } from "rxjs";
import { NetworkCallFeedbackService } from "./network-call-feedback.service";

@Injectable({
    providedIn: 'root'
})
export class NetworkCallFeedbackInterceptor implements HttpInterceptor {
    constructor(private networkFeedbackService: NetworkCallFeedbackService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.networkFeedbackService.setFeedbackState(true);
        return next.handle(req).pipe(finalize(() => this.networkFeedbackService.setFeedbackState(false)));
    }
}
