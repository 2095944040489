import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, of, throwError } from "rxjs";
import { NotificatoinService } from "./notificatoin.service";

const ERROR_RESPONSE: any = {
    success: false,
    status: false,
    message: '',
    data: []
}

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
    
    constructor(private alertService: NotificatoinService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
            this.alertService.notify('Something Went Wrong!').error().show();
            return throwError(() => error);
        }));
    }
    
}