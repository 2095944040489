import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupplierUniversalService {

  constructor(private http: HttpClient) { }

  getUniversalList(page:number,size:number,filter:any){
    let reqParams=new HttpParams();
    reqParams=reqParams.append("page",page);
    reqParams=reqParams.append("size",size);
    reqParams=reqParams.append("vertical",filter.vertical);
    reqParams=reqParams.append("region",filter.region);
    reqParams=reqParams.append("supplierId",filter.supplierId);
    return this.http.get(`${environment.baseURL}/api/v1/supplier-list`,{
      params: reqParams
    });
  }
  getVerticalList(){
    return this.http.get(`${environment.baseURL}/api/v1/verticals`);
  }
  getRegionList(){
    return this.http.get(`${environment.baseURL}/api/v1/regions`);
  }
  getAdvanceInvoiceMappingList(supplierId:any){
    let reqParams=new HttpParams();
    reqParams=reqParams.append("supplierId",supplierId);
    return this.http.get(`${environment.baseURL}/api/v1/supplier-advance-po-mrn-list`,{
      params: reqParams
    })
  }

  getAdvanceInvoicePopupList(poId:any){
    let reqParams=new HttpParams();
    reqParams=reqParams.append("poId",poId);
    return this.http.get(`${environment.baseURL}/api/v1/supplier-po-invoices-list`,{
      params: reqParams
    })
  }

  getAdvanceVsCreditList(supplierId:any){
    let reqParams=new HttpParams();
    reqParams=reqParams.append("supplierId",supplierId);
    return this.http.get(`${environment.baseURL}/api/v1/supplier-advance-credit-order-details`,{
      params: reqParams
    })
  }
  getGmList(supplierId:any){
    let reqParams=new HttpParams();
    reqParams=reqParams.append("supplierId",supplierId);
    return this.http.get(`${environment.baseURL}/api/v1/supplier-msn-list`,{
      params: reqParams
    })
  }

  public getDebitValueAndGSTList(supplierId: number) {
    let params = new HttpParams({fromObject: {supplierId}});
    return this.http.get(`${environment.baseURL}/api/v1/supplier-debit-value-gst-list`, {params});
  }
}
