import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '../../services/authguard/auth.service';

@Component({
  selector: 'csd-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent {
  constructor(public authService:AuthService) { }

  ngOnInit(): void {
  }
}
