import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, timer, map } from 'rxjs';
import { AlertType } from '../../services/notificatoin.service';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
  public time: Observable<Date> = timer(0, 1000).pipe(map(() => new Date()));
    public alertTypeTitle: string = "Notification";
    public alertTypeIcon: string = 'info';
    constructor(@Inject(MAT_SNACK_BAR_DATA) public snackbarData: AlertNotificationData, public snackbarRef: MatSnackBarRef<NotificationComponent>) {
        switch (snackbarData.type) {
            case AlertType.SUCCESS:
                this.alertTypeTitle = "SUCCESS";
                this.alertTypeIcon = "check_circle";
                break;
            case AlertType.ERROR:
                this.alertTypeTitle = "ERROR";
                this.alertTypeIcon = "close";
                break;
            default:
                this.alertTypeTitle = "NOTIFICATION";
                this.alertTypeIcon = "info";
        }
    }
    ngOnInit(): void {}

}
export class AlertNotificationData {
  title: string | undefined;
  body?: string;
  type: AlertType | undefined;
}
