<div class="top">
    <span class="lft">
        <h2>Advance VS Credit Payment Term</h2>
    </span>
    <mat-icon mat-dialog-close class="close">
        close
    </mat-icon>
</div>
<section class="table-set">
    <div class="inner-cpsticky example-container mat-elevation-z8">
        <table mat-table [dataSource]="dataSource$ | async">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>
                    Supplier ID
                </th>
                <td mat-cell *matCellDef="let element let i=index">
                    {{element.id}}
                </td>
            </ng-container>
            <ng-container matColumnDef="creditAdvance">
                <th mat-header-cell *matHeaderCellDef>Credit/ Advance</th>
                <td mat-cell *matCellDef="let element"> {{ element.creditAdvance }} </td>
            </ng-container>

            <ng-container matColumnDef="invoiceValue">
                <th mat-header-cell *matHeaderCellDef>
                    Invoice Value
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.invoiceValue}}
                </td>
            </ng-container>
            

            <ng-container matColumnDef="vertical">
                <th mat-header-cell *matHeaderCellDef>Vertical</th>
                <td mat-cell *matCellDef="let element"> {{ element.vertical }} </td>
            </ng-container>  
            

            <tr mat-header-row class="row" class="nestedHeader row" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</section>