import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {
  constructor(private authService: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.accessToken) {
      let authHeader = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.accessToken);
      req = req.clone({
        headers: authHeader
      });
      return next.handle(req);
    }
    return next.handle(req);
  }
}
