import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-data-summary-pagination',
  templateUrl: './data-summary-pagination.component.html',
  styleUrls: ['./data-summary-pagination.component.scss']
})
export class DataSummaryPaginationComponent implements OnInit, OnChanges {

  public pageSize: FormControl = new FormControl(30);
  @Input() public pageSizeOptions: number[] = [10, 20, 30, 50, 100, 200, 300, 500];
  @Input() public totalElements: number = 0;

  @Input()
  public defaultPageSize: number | undefined;
  public currentPage: FormControl = new FormControl(1);
  public totalPages: number = 1;

  @Output()  pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  constructor() { }

  public next() {
    this.currentPage.setValue(this.currentPage.value + 1);
  }

  public previous() {
    this.currentPage.setValue(this.currentPage.value - 1);
  }

  public reset() {
    this.currentPage.setValue(1);
    this.totalElements = 0;
    this.totalPages = 1;
  }

  ngOnInit(): void {
    if (this.defaultPageSize) {
      this.pageSize.setValue(this.defaultPageSize);
    }

    this.pageSize.valueChanges.subscribe(ps => {
      this.pageChange.emit({ limit: this.pageSize.value, page: this.currentPage.value });
      this.calculateTotalPages();
    });

    this.currentPage.valueChanges.subscribe(pageIndex => {
      this.pageChange.emit({
        limit: this.pageSize.value,
        page: pageIndex
      });
    });
  }

  private calculateTotalPages() {
    if (this.totalElements) {
      this.totalPages = Math.ceil(this.totalElements / this.pageSize.value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalElements'].currentValue) {
      this.calculateTotalPages();
    }
  }
}

export interface PageEvent {
  limit: number;
  page: number;
}