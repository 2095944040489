import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  let authService=inject(AuthService);
  let router=inject(Router);
  if(!authService.accessToken){
    return router.createUrlTree(
      ['/auth/login']);
  }else{
    return true;
  }
};
