import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, catchError, map, of } from 'rxjs';
import { NotificatoinService } from 'src/app/shared/services/notificatoin.service';
import { SupplierUniversalService } from 'src/app/shared/services/supplier-universal.service';

@Component({
  selector: 'app-advance-invoice-popup',
  templateUrl: './advance-invoice-popup.component.html',
  styleUrls: ['./advance-invoice-popup.component.scss']
})
export class AdvanceInvoicePopupComponent implements OnInit{
  dataSource$: Observable<any> = this.supplierUniversalService.getAdvanceInvoicePopupList(this.id).pipe(
    catchError((err) => {
      this.alertService.notify(err.error.message).error().show();
      return of({status: false, data: []})
    }),
    map((item: any) => item.data)
  );;
  displayedColumns:any=['supplierInvoice','supplierValue','poValue','advanceValue'];
  constructor(private alertService: NotificatoinService,private supplierUniversalService: SupplierUniversalService,@Inject(MAT_DIALOG_DATA) public id: any){};
  ngOnInit(): void {
  }

}