<div class="top">
    <span class="lft">
        <h2>Debit Value and GST</h2>
    </span>
    <mat-icon mat-dialog-close class="close">
        close
    </mat-icon>
</div>
<section class="table-set">
    <div class="inner-cpsticky example-container mat-elevation-z8">
        <table mat-table [dataSource]="dataSource$ | async">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>
                    Supplier ID
                </th>
                <td mat-cell *matCellDef="let element let i=index">
                    {{element.id}}
                </td>
            </ng-container>
            <ng-container matColumnDef="invoiceValue">
                <th mat-header-cell *matHeaderCellDef>
                    Invoice Value
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.invoiceValue}}
                </td>
            </ng-container>
            
            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Value</th>
                <td mat-cell *matCellDef="let element"> {{ element.value }} </td>
            </ng-container>

            <ng-container matColumnDef="gst">
                <th mat-header-cell *matHeaderCellDef>GST</th>
                <td mat-cell *matCellDef="let element"> {{ element.gst }} </td>
            </ng-container>  
            
            <ng-container matColumnDef="debitValue">
                <th mat-header-cell *matHeaderCellDef>Debit Value</th>
                <td mat-cell *matCellDef="let element"> {{ element.debitValue }} </td>
            </ng-container>

            <tr mat-header-row class="row" class="nestedHeader row" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</section>