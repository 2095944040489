export const environment = {
    // baseURL: 'http://localhost:3200',
    baseURL: 'https://supplier-universeapi.moglilabs.com',
    production: false,
    microsoft: {
      KEY: '7ef76858-5a61-4c38-9937-e94e999bac4a',
      authorityUrl: 'https://login.microsoftonline.com/421e9584-87ff-424f-95b8-1bf46b70db99/',
      tenentId: '421e9584-87ff-424f-95b8-1bf46b70db99'
    },
    acocuntServiceURL: 'https://mce-account-service-qa1.moglilabs.com/account/auth/login'
};
