<section class="card">
    <div class="card-set">
        <div class="example-card">
            <mat-card class="mat-card">
                  <div class="head">
                  <p class="info">
                    <span class="heading">Supplier ID :</span>
                    <span class="data">{{headerData.supplier_id}}</span>
                  </p>
                  <p class="info">
                    <span class="heading">Supplier name :</span>
                    <span class="data">{{headerData.supplier_name}}</span>
                  </p>
                  <p class="info">
                    <span class="heading">Average GM% :</span>
                    <span class="data">{{headerData.gm_percent}}</span>
                  </p>
                  <p class="info">
                    <span class="heading">Average GM% (Other Supplier) :</span>
                    <span class="data">{{headerData.other_gm_percent}}</span>
                  </p>
                  <p class="info">
                    <span class="heading">Category :</span>
                    <span class="data">{{headerData.category}}</span>
                  </p>
                  <p class="info">
                    <span class="heading">Risk Assesment :</span>
                    <span class="data">
                      <div [ngSwitch]="headerData.riskFactor">
                        <div *ngSwitchCase="-1">
                          <mat-icon class="icon-down">trending_down</mat-icon>
                        </div>
                        <div *ngSwitchCase="0">
                          <mat-icon class="icon-flat">trending_flat</mat-icon>
                        </div>
                        <div *ngSwitchCase="1">
                          <mat-icon class="icon-up">trending_up</mat-icon>
                        </div>
                    </div>
                    </span>
                  </p>
                </div>
            </mat-card>
        </div>
    </div>
  <div class="card-set">
    <div class="example-card1">
      <mat-card class="mat-card">
        <mat-card-header class="mat-card-header">
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>GM%</mat-card-title>
          <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
        </mat-card-header>
            <div class="inner-cpsticky example-container mat-elevation-z8 table-set">
                <table mat-table [dataSource]="supplierMsnList$ | async">
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>
                            Category
                        </th>
                        <td mat-cell *matCellDef="let element let i=index">
                            <!-- {{element.category}} -->
                            --
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="gm">
                        <th mat-header-cell *matHeaderCellDef>
                            GM%
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.gm_percent}}
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="ogm">
                        <th mat-header-cell *matHeaderCellDef>Other Supplier <br> GM%</th>
                        <td mat-cell *matCellDef="let element"> {{ element.other_gm_percent }} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="otherSupplier">
                        <th mat-header-cell *matHeaderCellDef>Other Supplier ID</th>
                        <td mat-cell *matCellDef="let element"> {{ element.other_supplier_id }} </td>
                    </ng-container>  
                    
                    <ng-container matColumnDef="msnId">
                        <th mat-header-cell *matHeaderCellDef>MSN Id</th>
                        <td mat-cell *matCellDef="let element"> {{ element.msn }} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="msnName">
                        <th mat-header-cell *matHeaderCellDef>MSN Name</th>
                        <td mat-cell class="product-name" *matCellDef="let element"> 
                            {{element.product_name}}
                         </td>
                    </ng-container>
                    <tr mat-header-row class="nestedHeader row" *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                    <tr mat-row (click)="gmPop(row.category)" class="point" *matRowDef="let row; columns: displayedColumns1;"></tr>
                </table>
            </div>
      </mat-card>
    </div>
    <div class="example-card2">
      <mat-card class="mat-card">
        <mat-card-header class="mat-card-header">
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>Debit Value and GST </mat-card-title>
          <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
        </mat-card-header>
        <div class="inner-cpsticky example-container mat-elevation-z8 table-set">
            <table mat-table [dataSource]="supplierDebitValueAndGstList$ | async">
                <ng-container matColumnDef="gst">
                    <th mat-header-cell *matHeaderCellDef>
                        GST
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.gst}}
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="debit">
                    <th mat-header-cell *matHeaderCellDef>Debit Value</th>
                    <td mat-cell *matCellDef="let element"> {{ element.debit }} </td>
                </ng-container>
                <tr mat-header-row class="nestedHeader row" *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                <tr mat-row (click)="debitGSTPopup(row.debit)" class="point" *matRowDef="let row; columns: displayedColumns2;"></tr>
            </table>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="card-set">
    <div class="example-card1">
      <mat-card class="mat-card">
        <mat-card-header class="mat-card-header">
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>Advance Invoice Mapping</mat-card-title>
          <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
        </mat-card-header>
            <div class="inner-cpsticky example-container mat-elevation-z8 table-set">
                <table mat-table [dataSource]="supplierAdvanceMappingList$ | async">
                    <ng-container matColumnDef="supplierPO">
                        <th mat-header-cell *matHeaderCellDef>
                            Supplier PO
                        </th>
                        <td mat-cell *matCellDef="let element let i=index">
                            {{element.po_id}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="advance">
                        <th mat-header-cell *matHeaderCellDef>
                            Advance
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.advance_value}}
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="mapValue">
                        <th mat-header-cell *matHeaderCellDef>Map Value</th>
                        <td mat-cell *matCellDef="let element"> {{ element.invoice_mapped_total_amount }} </td>
                    </ng-container>
                    <tr mat-header-row class="nestedHeader row" *matHeaderRowDef="displayedColumns3; sticky: true"></tr>
                    <tr (click)="advanceInvoicePopup(row.po_id)" class="point" mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                </table>
            </div>
      </mat-card>
    </div>
    <div class="example-card2">
      <mat-card class="mat-card">
        <mat-card-header class="mat-card-header">
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>Advance VS Credit Payment Term </mat-card-title>
          <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
        </mat-card-header>
        <div class="inner-cpsticky example-container mat-elevation-z8 table-set">
            <table mat-table [dataSource]="advanceVsCreditList$ | async">
              <ng-container matColumnDef="vertical">
                <th mat-header-cell *matHeaderCellDef>
                    Vertical
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.vertical}}
                </td>
            </ng-container>
                <ng-container matColumnDef="advanceOrder">
                    <th mat-header-cell *matHeaderCellDef>
                        No of Orders<br>(Advance)
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.advanceOrders}}
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="creditOrder">
                    <th mat-header-cell *matHeaderCellDef>No of Orders <br> (Credit)</th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.creditOrders }}
                     </td>
                </ng-container>
                <tr mat-header-row class="nestedHeader row" *matHeaderRowDef="displayedColumns4; sticky: true"></tr>
                <tr mat-row (click)="advanceVsCredit(row.orderCredit)" class="point" *matRowDef="let row; columns: displayedColumns4;"></tr>
            </table>
        </div>
      </mat-card>
    </div>
  </div>
</section>
