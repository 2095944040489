import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { SupplierUniversalDashboardComponent } from './components/supplier-universal-dashboard/supplier-universal-dashboard.component';
import { MicrosoftLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './shared/services/authguard/auth-interceptor.service';
import { NetworkCallFeedbackInterceptor } from './shared/services/network-call-feedback.interceptor';
import { ErrorHandlerInterceptor } from './shared/services/error-handler.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataSummaryPaginationComponent } from './shared/components/data-summary-pagination/data-summary-pagination.component';
import { SupplierInfoComponent } from './components/supplier-info/supplier-info.component';
import { GmPopupComponent } from './components/gm-popup/gm-popup.component';
import { DebitValueGstPopupComponent } from './components/debit-value-gst-popup/debit-value-gst-popup.component';
import { AdvanceInvoicePopupComponent } from './components/advance-invoice-popup/advance-invoice-popup.component';
import { AdvanceCreditPopupComponent } from './components/advance-credit-popup/advance-credit-popup.component';
import { environment } from 'src/environments/environment.development';

@NgModule({
  declarations: [
    AppComponent,
    SupplierUniversalDashboardComponent,
    DataSummaryPaginationComponent,
    SupplierInfoComponent,
    GmPopupComponent,
    DebitValueGstPopupComponent,
    AdvanceInvoicePopupComponent,
    AdvanceCreditPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkCallFeedbackInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider(environment.microsoft.KEY, {
              authority: environment.microsoft.authorityUrl
            })
          }
        ]
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
