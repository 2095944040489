import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NetworkCallFeedbackService } from '../../services/network-call-feedback.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent {
  
  constructor(public networkFeedbackService: NetworkCallFeedbackService) { }

  ngOnInit(): void {
  }

}
