import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-gm-popup',
  templateUrl: './gm-popup.component.html',
  styleUrls: ['./gm-popup.component.scss']
})
export class GmPopupComponent implements OnInit {
  dataSource$: Observable<any> = of(DUMMY);
  displayedColumns:any=['id','invoiceValue','msnId','msnValueS1','msnValueS2'];
  constructor(@Inject(MAT_DIALOG_DATA) public id: any){};
  ngOnInit(): void {
    console.log(this.id);
    
  }

}
const DUMMY=[
  { id:1, invoiceValue:100, msnId:24, msnValue1:234, msnValue2:431 },
  { id:2, invoiceValue:80, msnId:22, msnValue1:124, msnValue2:430 },
  { id:3, invoiceValue:70, msnId:23, msnValue1:123, msnValue2:321 }
];
