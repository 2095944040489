import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-advance-credit-popup',
  templateUrl: './advance-credit-popup.component.html',
  styleUrls: ['./advance-credit-popup.component.scss']
})
export class AdvanceCreditPopupComponent implements OnInit{
  dataSource$: Observable<any> = of(DUMMY);
  displayedColumns:any=['id','creditAdvance','invoiceValue','vertical'];
  constructor(@Inject(MAT_DIALOG_DATA) public id:any){};

  ngOnInit(): void {
    console.log(this.id)
    
  }

}
const DUMMY=[
  { id:1, invoiceValue:10200, vertical:12, gst:18, creditAdvance:12345 },
  { id:2, invoiceValue:100, vertical:11, gst:18, creditAdvance:12345 },
  { id:3, invoiceValue:1000, vertical:10, gst:18, creditAdvance:1235 }
];