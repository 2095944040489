import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { NotificatoinService } from 'src/app/shared/services/notificatoin.service';
import { SupplierUniversalService } from 'src/app/shared/services/supplier-universal.service';

@Component({
  selector: 'app-supplier-universal-dashboard',
  templateUrl: './supplier-universal-dashboard.component.html',
  styleUrls: ['./supplier-universal-dashboard.component.scss']
})
export class SupplierUniversalDashboardComponent implements OnInit{
  public searchFilters: FormGroup;
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  verticals$:Observable<any> | undefined= this.supplierUniversalService.getVerticalList().pipe(
    catchError((err) => {
      this.alertService.notify(err.error.message).error().show();
      return of({status: false, data: []})
    }),
    map((item: any) => item.data)
  );
  regions$:Observable<any> | undefined= this.supplierUniversalService.getRegionList().pipe(
    catchError((err) => {
      this.alertService.notify(err.error.message).error().show();
      return of({status: false, data: []})
    }),
    map((item: any) => item.data)
  );
  public totalRecords: number = 0;
  displayedColumns=["supplierId","supplierName","avgGM","avgGM2","category","risk"];
  dataSource:any=[];
  constructor(private fb: FormBuilder,
    private supplierUniversalService:SupplierUniversalService,
    private alertService: NotificatoinService,
    public router :Router) {
      this.searchFilters = this.fb.group({
        supplierId:[""],
        vertical: [""],
        region:[""]
      });
    }
  ngOnInit(): void {
    this.universalListing(1,100);
    
  }
  handlePageChange(event: PageEvent) {
    
}
  universalListing(page:number,size:number){
this.supplierUniversalService.getUniversalList(page,size,this.searchFilters.value).subscribe({
  next:(resp:any)=>{
    if(resp.status){
      if(resp.data.length>0){
      this.dataSource=resp.data;}
      else{
      this.alertService.notify("No data found").error().show();
    }
    }else{
      this.alertService.notify(resp.message).error().show();
    }
  },
  error:(err:any)=>{
    this.alertService.notify(err.error.message).error().show();
  },
  complete:()=>{
    this.cdr.detectChanges();
  }
})
  }
  download(){
    console.log("download not working right now");
  }
  searchSubmit(){
    let filters= this.searchFilters.value;
    if(filters.vertical || filters.region || filters.supplierId)
    this.universalListing(1,100);
    else
    this.alertService.notify("Please add values first").error().show();
  }
  openInfoPage(element:any){
    let url = this.router.createUrlTree(['supplier-info'],{queryParams:element,queryParamsHandling: 'merge'});
    window.open(url.toString(), '_blank')
  }
  onReset(){
    let obj={
      supplierId:"",
      vertical:"",
      region:""
    }
    this.searchFilters.reset(obj);
    this.universalListing(1,100);
  }

}
export interface PageEvent {
  limit: number;
  page: number;
}