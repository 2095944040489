<div class="app-alert">
    <div class="alert-header">
        <div class="box left">
            <mat-icon class="material-icons-outlined">{{alertTypeIcon}}</mat-icon>
            <p>{{alertTypeTitle}}</p>
        </div>
        <div class="box right">
            <p>{{time | async | date: 'hh:mma'}}</p>
            <mat-icon class="material-icons-outlined" (click)="snackbarRef.dismiss()">cancel</mat-icon>
        </div>
    </div>
    <div class="alert-body">
        <div class="alert-title">{{snackbarData.title}}</div>
        <p class="alert-body" *ngIf="snackbarData.body">{{snackbarData.body}}</p>
    </div>
</div>