import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { LoginComponent } from './shared/components/login/login.component';
import { authGuard } from './shared/services/authguard/auth.guard';
import { SupplierUniversalDashboardComponent } from './components/supplier-universal-dashboard/supplier-universal-dashboard.component';
import { SupplierInfoComponent } from './components/supplier-info/supplier-info.component';

const routes: Routes = [
  {
      path: '',
      component: LayoutComponent,
      children: [
          { path: 'dashboard', component: SupplierUniversalDashboardComponent},
          { path: 'supplier-info', component: SupplierInfoComponent},
          { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' }
      ], canActivate: [authGuard],
  },
  { path: 'auth/login', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
