import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkCallFeedbackService {
  private _networkCallInProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _progressValueSubject: BehaviorSubject<number> = new BehaviorSubject<number>(68);
  public networkCallInProgress: Observable<boolean> = this._networkCallInProgressSubject.asObservable();
  public progressValue: Observable<number> = this._progressValueSubject.asObservable();
  constructor() { }
  public setFeedbackState(value: boolean) {
    this._networkCallInProgressSubject.next(value);
  }
}
