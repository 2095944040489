import { Component, Inject, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { SupplierUniversalService } from 'src/app/shared/services/supplier-universal.service';
import { GmPopupComponent } from '../gm-popup/gm-popup.component';
import { DebitValueGstPopupComponent } from '../debit-value-gst-popup/debit-value-gst-popup.component';
import { AdvanceInvoicePopupComponent } from '../advance-invoice-popup/advance-invoice-popup.component';
import { AdvanceCreditPopupComponent } from '../advance-credit-popup/advance-credit-popup.component';
import { NotificatoinService } from 'src/app/shared/services/notificatoin.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-supplier-info',
  templateUrl: './supplier-info.component.html',
  styleUrls: ['./supplier-info.component.scss'],
})
export class SupplierInfoComponent implements OnInit {
  private supplierUniversalService: SupplierUniversalService = inject(SupplierUniversalService);
  public dialog: MatDialog = inject(MatDialog);
  private alertService: NotificatoinService = inject(NotificatoinService);
  public route: ActivatedRoute = inject(ActivatedRoute);
  private supplierId$: Observable<number> = this.route.queryParams.pipe(map((params: any) => params.supplier_id));
  supplierMsnList$: Observable<any> = this.supplierId$.pipe(switchMap(supplierId => {
    return this.supplierUniversalService
    .getGmList(supplierId)
    .pipe(
      catchError(this.handleExceptionFn),
      map((item: any) => item.data)
    );
  }));
  supplierDebitValueAndGstList$: Observable<any> = this.supplierId$.pipe(switchMap(supplierId => {
    return this.supplierUniversalService
    .getDebitValueAndGSTList(supplierId)
    .pipe(
      catchError(this.handleExceptionFn),
      map((item: any) => item.data)
    );
  }));
  supplierAdvanceMappingList$: Observable<any> = this.supplierId$.pipe(switchMap(supplierId => {
    return this.supplierUniversalService
    .getAdvanceInvoiceMappingList(supplierId)
    .pipe(
      catchError(this.handleExceptionFn),
      map((item: any) => item.data)
    );
  })); 
  advanceVsCreditList$: Observable<any> = this.supplierId$.pipe(switchMap(supplierId => {
    return this.supplierUniversalService
    .getAdvanceVsCreditList(supplierId)
    .pipe(
      catchError(this.handleExceptionFn),
      map((item: any) => item.data)
    );
  })); 
  displayedColumns1: any[] = [
    'category',
    'gm',
    'ogm',
    'otherSupplier',
    'msnId',
    'msnName',
  ];
  displayedColumns2: any[] = ['gst', 'debit'];
  displayedColumns3: any[] = ['supplierPO', 'advance', 'mapValue'];
  displayedColumns4: any[] = ['vertical', 'advanceOrder', 'creditOrder'];
  headerData: any = this.route.snapshot.queryParams;
  ngOnInit(): void {}

  private handleExceptionFn = (err: any) => {
    this.alertService.notify(err.error.message).error().show();
    return of({ status: false, data: [] });
  } 

  gmPop(id: any) {
    const dialogRef = this.dialog.open(GmPopupComponent, {
      disableClose: false,
      hasBackdrop: true,
      panelClass: 'gmWrap',
      width: '85vw',
      height: '55vh',
      data: id,
    });
  }
  debitGSTPopup(id: any) {
    const dialogRef = this.dialog.open(DebitValueGstPopupComponent, {
      disableClose: false,
      hasBackdrop: true,
      panelClass: 'debitGSTWrap',
      width: '85vw',
      height: '55vh',
      data: id,
    });
  }
  advanceInvoicePopup(id: any) {
    const dialogRef = this.dialog.open(AdvanceInvoicePopupComponent, {
      disableClose: false,
      hasBackdrop: true,
      panelClass: 'advanceInvoiceWrap',
      width: '85vw',
      height: '55vh',
      data: id,
    });
  }
  advanceVsCredit(id: any) {
    const dialogRef = this.dialog.open(AdvanceCreditPopupComponent, {
      disableClose: false,
      hasBackdrop: true,
      panelClass: 'advnceCreditWrap',
      width: '85vw',
      height: '55vh',
      data: id,
    });
  }
}
const DUMMY = [
  {
    category: '123',
    gm: 1,
    ogm: 2,
    otherSupplier: 'naruto',
    msnId: 123,
    msnName: 'hinata',
  },
  {
    category: '123',
    gm: 1,
    ogm: 2,
    otherSupplier: 'naruto',
    msnId: 123,
    msnName: 'hinata',
  },
  {
    category: '123',
    gm: 1,
    ogm: 2,
    otherSupplier: 'naruto',
    msnId: 123,
    msnName: 'hinata',
  },
  {
    category: '123',
    gm: 1,
    ogm: 2,
    otherSupplier: 'naruto',
    msnId: 123,
    msnName: 'hinata',
  },
];
const DUMMY2 = [
  { supplierName: 'sasuke', gst: 18, debit: 5000 },
  { supplierName: 'sasuke', gst: 18, debit: 5000 },
  { supplierName: 'sasuke', gst: 18, debit: 5000 },
  { supplierName: 'sasuke', gst: 18, debit: 5000 },
  { supplierName: 'sasuke', gst: 18, debit: 5000 },
  { supplierName: 'sasuke', gst: 18, debit: 5000 },
];
const DUMMY4 = [
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
  {
    paymentTermCredit: 'Advance',
    orderCredit: 12,
    orderCreditVertical: 'MASS',
    paymentTermAdvance: 'Advance',
    orderAdvance: 12,
    orderAdvanceVertical: 'MASS',
  },
];
