import { MicrosoftLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificatoinService } from '../notificatoin.service';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  socialUser: SocialUser | undefined;
  protected jwtToken: any;
  private reqBody = new Object();
  public interceptorToken:any=null;

  constructor(private router:Router,private socialAuthService: SocialAuthService, private http: HttpClient, private alertService: NotificatoinService) {
    this.login();
  }

  login() {
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      localStorage.setItem('email', user.email);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('provider', user.provider);
      this.jwtToken = user.authToken;
      localStorage.setItem('jwtToken', this.jwtToken);
      this.loginToken().subscribe({
        next: (resp: any) => {
          if (resp.success == true) {
            localStorage.setItem('loginToken', resp.data.token);
            localStorage.removeItem('jwtToken');
            console.log(resp);
            if(!resp.data.userRoleList.find((item: string) => item === 'ONEVIEWUSER')){
              localStorage.clear();
              this.alertService.notify('Not Authorized').error().show();
              return;
            }
            this.router.navigate(['/dashboard']);
          } else {
            localStorage.clear();
            this.alertService.notify('Not Authorized').error().show();
          }
        },
        error: (err: any) => this.alertService.notify('Not Authorized').error().show()
      });
    });

  }
  loginToken() {
    this.reqBody = {
      'idToken': localStorage.getItem('jwtToken'),
      'email': localStorage.getItem('email'),
      'provider': localStorage.getItem('provider')
    };
    const header = new HttpHeaders({'source':'ONE_VIEW'});
    return this.http.post(environment.acocuntServiceURL,this.reqBody,{headers: header} );//api call
  }
  public get accessToken() {
    return localStorage.getItem('loginToken');
  }
  public logout():void {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  loginWithMicrosoft() {
    this.socialAuthService.signIn(MicrosoftLoginProvider.PROVIDER_ID);
  }
}
