import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-debit-value-gst-popup',
  templateUrl: './debit-value-gst-popup.component.html',
  styleUrls: ['./debit-value-gst-popup.component.scss']
})
export class DebitValueGstPopupComponent implements OnInit{
  dataSource$: Observable<any> = of(DUMMY);
  displayedColumns:any=['id','invoiceValue','value','gst','debitValue'];
  constructor(@Inject(MAT_DIALOG_DATA) public id: any){};
  ngOnInit(): void {
    console.log(this.id);
  }

}
const DUMMY=[
  { id:1, invoiceValue:123, value:234, gst:'2131edwe211', debitValue:123345 },
  { id:2, invoiceValue:13, value:134, gst:'2fdsd1x1edwe211', debitValue:132345 },
  { id:3, invoiceValue:23, value:134, gst:'2czd1x1edwe211', debitValue:12345 },
  
];
