import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ConfirmationComponent } from '../components/confirmation/confirmation.component';
import { NotificationComponent } from '../components/notification/notification.component';



@Injectable({
  providedIn: 'root'
})
export class NotificatoinService {
  constructor(private snackbar: MatSnackBar, private dialog: MatDialog) {}


  public notify(title: string) : NotificationAlertBuilder {
      return new NotificationAlertBuilder(title, this.snackbar);
  }

  public confirm(config: ConfirmActionDialogConfig) : Observable<boolean> {
      return this.dialog.open(ConfirmationComponent, {
          hasBackdrop: true,
          minWidth: '30vw',
          data: config
      }).afterClosed();
  }
}
class NotificationAlertBuilder {
  private bodyText?: string;
  private type?: AlertType = AlertType.DEFAULT;
  private durationTime?: number = 2000;
  private alertTypeClass: string = '';
  constructor(private title: string, private snackbar: MatSnackBar) {}

  public body(bodyText: string) : NotificationAlertBuilder {
      this.bodyText = bodyText;
      return this;
  }

  public success() : NotificationAlertBuilder {
      this.type = AlertType.SUCCESS;
      this.alertTypeClass = 'alert-success';
      return this;
  }
  public error() : NotificationAlertBuilder {
      this.type = AlertType.ERROR;
      this.alertTypeClass = 'alert-error';
      return this;
  }

  public notification() : NotificationAlertBuilder {
      this.type = AlertType.NOTIFICATION;
      this.alertTypeClass = 'alert-notification';
      return this;
  }

  public show() : MatSnackBarRef<NotificationComponent> {
      const classes: string[] = ['app-alert-box'];
      if (this.alertTypeClass) {
          classes.push(this.alertTypeClass);
      }
      return this.snackbar.openFromComponent(NotificationComponent, {
          data: {
              title: this.title,
              body: this.bodyText,
              type: this.type
          },
          duration: this.durationTime ? this.durationTime : 3000,
          panelClass: classes
      });
  }
}

export enum AlertType {
  DEFAULT, SUCCESS, ERROR, NOTIFICATION
}

export interface ConfirmActionDialogConfig {
  title: string;
  body?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}
