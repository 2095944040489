<div class="top">
    <span class="lft">
        <h2>Advance Invoice Mapping</h2>
    </span>
    <mat-icon mat-dialog-close class="close">
        close
    </mat-icon>
</div>
<section class="table-set">
    <div class="inner-cpsticky example-container mat-elevation-z8">
        <table mat-table [dataSource]="dataSource$ | async">
            <ng-container matColumnDef="supplierInvoice">
                <th mat-header-cell *matHeaderCellDef>
                    Supplier Invoice
                </th>
                <td mat-cell *matCellDef="let element let i=index">
                    {{element.supplier_invoice_number}}
                </td>
            </ng-container>
            <ng-container matColumnDef="supplierValue">
                <th mat-header-cell *matHeaderCellDef>
                    Supplier Value
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.net_invoice_total}}
                </td>
            </ng-container>
            
            <ng-container matColumnDef="poValue">
                <th mat-header-cell *matHeaderCellDef>PO Value</th>
                <td mat-cell *matCellDef="let element"> {{ element.po_value }} </td>
            </ng-container>

            <ng-container matColumnDef="advanceValue">
                <th mat-header-cell *matHeaderCellDef>Advance Value</th>
                <td mat-cell *matCellDef="let element"> {{ element.advance_value }} </td>
            </ng-container>  

            <tr mat-header-row class="row" class="nestedHeader row" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</section>